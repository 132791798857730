import React, { Component } from 'react'

class MedInsights extends Component {
   componentDidMount() {
       window.location.replace("https://insights.medanswers.com/");
   }

   render() {
       return <div />
   }
}

export default MedInsights
